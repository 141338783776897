<!--
 * @Author: wjc
 * @Date: 2023-12-22 11:31:34
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-11-27 12:32:35
 * @Description: 
-->
<template>
  <div class="wh-full flex flex-col">
    <header
      class="flex-y-center justify-between fixed top-0 z-1 font-s-32px h-64px px-32px bg-white w-full"
    >
      <MLink to="/">
        <div class="flex-y-center">
          <img src="/logo.jpg" class="wh-40px mr-12px" />
          <div class="text-24px font-500 font-oblique">
            <span>海南省</span>
            <span class="color-primary">物业管理协会</span>
          </div>
        </div>
      </MLink>
      <div v-if="!isLogin" class="flex">
        <span
          class="to-login mr-30px"
          @click="
            () => {
              openLogin = true
              openRegister = true
            }
          "
        >
          <i class="i-ep-bell-filled color-primary w-18px"></i>
          <span class="ml-4px font-s-14px">注册</span>
        </span>
        <span class="to-login" @click="() => (openLogin = true)">
          <i class="i-ep-user-filled color-primary w-18px"></i>
          <span class="ml-4px font-s-14px">企业登录</span>
        </span>
      </div>
      <template v-else>
        <span class="flex-y-center">
          <el-dropdown>
            <el-space class="flex-y-center cursor-pointer">
              <el-avatar :size="30" class="bg-#F98E73!">
                <i class="i-ep-user-filled"></i>
              </el-avatar>
              <span class="font-500 font-s-13px text-overflow text-ellipsis max-w-70px">
                {{ userInfo.name }}
              </span>
              <span class="i-ep-arrow-down"></span>
            </el-space>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="toEnterPage">
                  <span class="font-s-12px">企业信息</span>
                </el-dropdown-item>
                <el-dropdown-item @click="onLogout">
                  <span class="font-s-12px">退出登录</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </span>
      </template>
    </header>
    <div class="index-container">
      <el-row justify="center" align="middle">
        <el-col class="ml-17vw" :xs="20" :md="16" :lg="16">
          <div class="index-title mb-76px text-left">
            <div class="color-primary font-s-48px font-600 at-xxl:font-s-96px">中国·海南</div>
            <div class="font-s-18px text-color-place mt-8px at-xxl:font-s-36px">
              {{ config.websiteTitle }}
            </div>
          </div>
          <div class="menu-container">
            <div class="to-home">
              <div class="title">协会官网</div>
              <MLink class="btn" to="/site">立即进入</MLink>
            </div>
            <div class="to-admin">
              <div class="title">企业入口</div>
              <div
                class="m-link transition-colors duration-300 hover:text-primary-color cursor-pointer font-s-1.4rem lt-xs:font-s-1.6rem btn"
                target="_blank"
                @click="toEnterSearchPage"
              >
                立即进入
              </div>
            </div>
            <div class="develop-ing">
              <div class="to-message">
                <div class="i-custom-index-m1 icon"></div>
                <div class="title">行业培训</div>
                <div>
                  <div>目前正在开发中</div>
                  <div>请谢心等待...</div>
                </div>
              </div>
              <div class="to-train">
                <div class="i-custom-index-m2 icon"></div>
                <div class="title">证书查询</div>
                <div>
                  <div>目前正在开发中</div>
                  <div>请谢心等待...</div>
                </div>
              </div>
              <div class="to-cert">
                <div class="i-custom-index-m3 icon"></div>
                <div class="title">企业招聘</div>
                <div>
                  <div>目前正在开发中</div>
                  <div>请谢心等待...</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <Footer class="footer"></Footer>
  </div>
  <ClientOnly>
    <Login
      :open="openLogin"
      :is-open-register="openRegister"
      @closeLogin="onCloseLogin"
      @closeRegister="() => (openRegister = false)"
    ></Login>
  </ClientOnly>
</template>

<script setup lang="ts">
  import { useUserStore } from '@/stores/modules/user/user'
  import { useLoginStore } from '@/stores/modules/user/login'
  import { sendMessage, windowManager } from '~/utils'
  import config from '~/constants'
  import Footer from '~/layouts/default/components/footer.vue'
  import Login from '~/components/Login/login.vue'
  definePageMeta({
    layout: false,
  })

  const $screen = useScreen()
  const userStore = useUserStore()
  const loginStore = useLoginStore()
  const userInfo = ref({})
  const isLogin = computed(() => {
    return loginStore.isLogin && userStore.getEnterpriseUserId
  })
  const openLogin = ref(false)
  const openRegister = ref(false)

  const onCloseLogin = () => {
    openLogin.value = false
    openRegister.value = false
  }

  const toEnterSearchPage = () => {
    sendMessage(
      import.meta.env.VITE_ADMIN_URL,
      `${import.meta.env.VITE_ADMIN_URL}/search?type=search`,
      {
        token: loginStore.getToken,
        refreshToken: loginStore.getRefreshToken,
      }
    )
  }

  const toEnterPage = async () => {
    sendMessage(
      import.meta.env.VITE_ADMIN_URL,
      `${import.meta.env.VITE_ADMIN_URL}/search?type=user`,
      {
        token: loginStore.getToken,
        refreshToken: loginStore.getRefreshToken,
      }
    )
  }

  const onLogout = () => {
    ElMessageBox.confirm('<strong>是否退出当前登录！</strong>', '', {
      dangerouslyUseHTMLString: true,
      confirmButtonText: '取消',
      cancelButtonText: '确认',
      closeOnClickModal: false,
      showClose: false,
      confirmButtonClass: 'el-button is-plain',
      cancelButtonClass: 'el-button el-button--danger',
    })
      .then((e) => e)
      .catch(() => {
        userInfo.value = {}
        loginStore.logout()
        windowManager.sendMessageToLogout(import.meta.env.VITE_ADMIN_URL, {
          type: 'logout',
        })
        // sendMessage(
        //   import.meta.env.VITE_ADMIN_URL,
        //   `${import.meta.env.VITE_ADMIN_URL}/search?type=logout`,
        //   {}
        // )
        navigateTo('/')
      })
  }

  watch(
    isLogin,
    (newVal) => {
      if (newVal) {
        userStore.getUserEnterpriseInfo().then((res) => {
          userInfo.value = res
        })
      }
    },
    { immediate: true }
  )
</script>

<style scoped lang="scss">
  .to-login {
    @apply flex-y-center text-color-1 cursor-pointer;
  }
  .index-container {
    @apply flex-1  flex-y-center mb-0px bg-bg-page pt-104px pb-136px;
    background: url('/images/index-bg.jpg') no-repeat;
    background-size: cover;
    background-position: center center;
    .menu-container {
      @apply grid gap-16px mb-24px;
      grid-template-columns: repeat(6, 102px);
      grid-template-rows: 180px 180px;
      grid-auto-flow: row dense;
      @screen at-xxl {
        grid-template-columns: repeat(6, 204px);
        grid-template-rows: 360px 360px;
        grid-auto-flow: row dense;
      }
      @screen lt-xs {
        @apply inline-flex flex-col wh-full;
        .to-home,
        .to-admin {
          @apply rounded-14px h-200px;
        }
        .to-message,
        .to-train,
        .to-cert {
          @apply py-6px;
        }
      }
      .to-home {
        background: url('/images/index-box2.svg') no-repeat;
        background-size: cover;
        background-position: center center;
        grid-column-start: span 3;
      }
      .to-admin {
        background: url('/images/index-box1.svg') no-repeat;
        background-size: cover;
        background-position: center center;
        grid-column-start: span 3;
      }
      .to-home,
      .to-admin {
        @apply flex flex-col justify-around items-start p-24px rounded-14px;
        .title {
          @apply font-s-28px color-white font-600;
        }
        .btn {
          @apply color-white rounded-24px px-16px py-6px cursor-pointer duration-300;
          border: 1px solid #f2f7fe;
          &:hover {
            @apply color-primary bg-#f2f7fe;
          }
        }
      }
      @screen at-xxl {
        .to-home,
        .to-admin {
          @apply flex flex-col justify-around items-start p-48px rounded-14px;
          .title {
            @apply font-s-56px color-white font-600;
          }
          .btn {
            @apply color-white rounded-24px px-16px py-6px cursor-pointer duration-300 font-s-24px;
            border: 1px solid #f2f7fe;
            &:hover {
              @apply color-primary bg-#f2f7fe;
            }
          }
        }
      }
      .develop-ing {
        @apply inline-flex gap-16px w-full;
        grid-column-start: 1;
        grid-column-end: 7;
        .to-message,
        .to-train,
        .to-cert {
          @apply flex-1 flex flex-col justify-center items-center rounded-14px bg-white;
          grid-column-start: span 2;
          .icon {
            @apply wh-40px mb-16px;
          }
          .title {
            @apply text-color-1 font-s-18px font-600 mb-8px;
          }

          & div:last-child {
            @apply font-s-12px text-color-place;
            & div:first-child {
              @apply mb-6px;
            }
          }
          @screen at-xxl {
            .icon {
              @apply wh-80px mb-32px;
            }
            .title {
              @apply text-color-1 font-s-36px font-600 mb-16px;
            }
            & div:last-child {
              @apply font-s-24px text-color-place;
              & div:first-child {
                @apply mb-6px;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    @apply h-252px relative b-0;
  }
</style>
